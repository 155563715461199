import { ClustersSetting } from 'src/components/Search/Common/Filters/utils';
import { SearchPage } from 'src/types/search/common';
import { ClusterKey, SelectedValuesTypeByClusterKey } from 'src/types/search/common/clusters';
import { CriteriaKey, OrderByOption } from 'src/types/search/common/criteria';
import { LabelOption } from 'src/types/search/resume/criteria';
import { SearchPeriodOption } from 'src/types/search/vacancy/criteria';

import getSharedSearchParams, {
    SharedSearchParams,
} from 'src/components/Search/Common/Filters/utils/getSearchParams/shared';
import { GetSearchParamsArgs } from 'src/components/Search/Common/Filters/utils/getSearchParams/types';

export type VacancySearchParams = SharedSearchParams & {
    [ClustersSetting.EnableSnippets]: [boolean];
    [CriteriaKey.EmployerId]: SelectedValuesTypeByClusterKey<typeof ClusterKey.Company>;
    [ClustersSetting.ForceFiltersSaving]?: 'true';
    [CriteriaKey.Salary]?: string;
    [LabelOption.OnlyWithSalary]?: 'true';
};

const getVacancySearchParams = (args: GetSearchParamsArgs): VacancySearchParams => {
    const searchParams = getSharedSearchParams(args) as VacancySearchParams;
    const {
        enableVacancySnippets,
        novaSorts,
        searchPage,
        searchClusters,
        vacancySearchResult,
        isExpNewEmploymentFilters,
    } = args;

    searchParams[ClustersSetting.EnableSnippets] = [enableVacancySnippets];

    const companyCluster = searchClusters[ClusterKey.Company];
    if (companyCluster) {
        searchParams[CriteriaKey.EmployerId] = companyCluster.selectedValues;
    }

    if (searchPage !== SearchPage.EmployerView && vacancySearchResult?.enableSimilarSavedSearch) {
        searchParams[ClustersSetting.ForceFiltersSaving] = 'true';
    }

    const compensationCluster = searchClusters[ClusterKey.Compensation];
    if (compensationCluster) {
        const { salary, onlyWithSalary } = compensationCluster.selectedValues;
        if (salary) {
            searchParams[CriteriaKey.Salary] = salary.toString();
        }
        if (onlyWithSalary) {
            searchParams[LabelOption.OnlyWithSalary] = 'true';
        }
    }

    if (searchParams[CriteriaKey.Area] && searchClusters[ClusterKey.Neighbours]) {
        searchParams.area = [
            ...(searchParams[CriteriaKey.Area] ?? []),
            ...(searchClusters[ClusterKey.Neighbours]?.selectedValues?.map(Number) ?? []),
        ];
    }

    // if we have metro and district, but don't have one region (after add Neighbours)
    // need delete metro and district
    if (searchParams[CriteriaKey.Area]?.length !== 1) {
        searchParams[CriteriaKey.Metro] = [];
        searchParams[CriteriaKey.District] = [];
    }

    // sorts overrides
    searchParams[CriteriaKey.OrderBy] = novaSorts.orderBy !== OrderByOption.Relevance ? novaSorts.orderBy : undefined;
    searchParams[CriteriaKey.SearchPeriod] =
        novaSorts.searchPeriod !== SearchPeriodOption.AllTime
            ? (novaSorts.searchPeriod as SearchPeriodOption)
            : undefined;

    if (isExpNewEmploymentFilters) {
        [CriteriaKey.Employment, CriteriaKey.Schedule, CriteriaKey.PartTime].forEach((oldFilterName) => {
            if (Object.keys(searchParams).includes(oldFilterName)) {
                delete searchParams[oldFilterName];
            }
        });
    }

    return searchParams;
};

export default getVacancySearchParams;
