import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { FilterType, Paging, LoadingActivityName } from 'src/components/SavedSearch/types';
import { PartTimeOption } from 'src/types/search/common/criteria';

import { CurrencyType } from 'src/models/currencies.types';

export interface VacancySavedSearchItem {
    allNew: boolean;
    area: string[];
    creationTimestamp: string;
    currencyCode: CurrencyType;
    emailActive: boolean;
    excludedText: string;
    id: number;
    industry: string[];
    linkBase: string;
    name: string;
    noNew: boolean;
    notFromAgency: boolean;
    onlyWithSalary: boolean;
    partTime: PartTimeOption[];
    professionalRole: string[];
    resultsAll: string;
    resultsNew: string;
    salary: string;
    searchArgs: string;
    searchField: string[];
    searchPeriod: string | boolean;
    sinceLastVisitSearchArgs: string;
    someNew: boolean;
    text: string;
    unknownSearch: boolean;
    withAddress: boolean;
    employmentForm: string[];
    withNightShifts: boolean;
    workFormat: string[];
    workingHours: string[];
    workScheduleByDays: string[];
    withAcceptTemporary: boolean;
    withInternship: boolean;
}

interface SavedSearchState {
    isAllSelected: boolean;
    selectedItems: number[];
    error?: boolean;
    email: string;
    emailVerified: boolean;
    savedSearch: VacancySavedSearchItem[];
    paging?: Paging;
    filterValue: FilterType;
    currentLoadingActivityName?: LoadingActivityName;
}

interface SavedSearchControls {
    isAllSelected: boolean;
    selectedItems: number[];
}

const SELECT_ALL_VACANCY_SAVED_SEARCHES = 'SELECT_ALL_VACANCY_SAVED_SEARCHES';
const SELECT_VACANCY_SAVED_SEARCH = 'SELECT_VACANCY_SAVED_SEARCH';
const GET_VACANCY_SAVED_SEARCH = 'GET_VACANCY_SAVED_SEARCH';
const VACANCY_SAVED_SEARCH_LOADING = 'VACANCY_SAVED_SEARCH_LOADING';

interface PayloadTypes {
    [SELECT_ALL_VACANCY_SAVED_SEARCHES]: SavedSearchControls;
    [SELECT_VACANCY_SAVED_SEARCH]: SavedSearchControls;
    [GET_VACANCY_SAVED_SEARCH]: { savedSearch: VacancySavedSearchItem[] };
    [VACANCY_SAVED_SEARCH_LOADING]: LoadingActivityName | undefined;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const selectAllSavedSearchesAction = actionCreator(SELECT_ALL_VACANCY_SAVED_SEARCHES);
export const selectVacancySavedSearchAction = actionCreator(SELECT_VACANCY_SAVED_SEARCH);
export const getVacancySavedSearchAction = actionCreator(GET_VACANCY_SAVED_SEARCH);
export const vacancySavedSearchLoadingAction = actionCreator(VACANCY_SAVED_SEARCH_LOADING);

export const INITIAL_STATE: SavedSearchState = {
    isAllSelected: false,
    selectedItems: [],
    error: false,
    email: '',
    emailVerified: true,
    savedSearch: [],
    filterValue: FilterType.All,
};

export default createReducer<SavedSearchState, PayloadTypes>(INITIAL_STATE, {
    [VACANCY_SAVED_SEARCH_LOADING]: (state, action) => ({ ...state, currentLoadingActivityName: action.payload }),
    [SELECT_ALL_VACANCY_SAVED_SEARCHES]: (state, action) => ({ ...state, ...action.payload }),
    [SELECT_VACANCY_SAVED_SEARCH]: (state, action) => ({ ...state, ...action.payload }),
    [GET_VACANCY_SAVED_SEARCH]: (state, action) => ({
        ...state,
        selectedItems: [],
        isAllSelected: false,
        ...action.payload,
    }),
});
