import { useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonScale } from 'bloko/blocks/button';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MarkAsViewedEvent } from 'Modules/Notices';
import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { SimpleNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    description1: 'notifications.employerHhRatingResultsNotification.description.1',
    description2: 'notifications.employerHhRatingResultsNotification.description.2',
    link: 'notifications.employerHhRatingResultsNotification.link',
};

const EmployerHhRatingResults: TranslatedComponent<SimpleNotificationComponentProps> = ({
    trls,
    onClose,
    ...notification
}) => {
    const userType = useSelector(({ userType }) => userType);
    const closeNotificationRef = useRef<VoidFunction>(null);
    const sendLinkClickAnalytics = () => {
        Analytics.sendHHEventButtonClick(`link_${notification.templateKey}`);
    };
    return (
        <SynchronizedNotification
            analyticsElementName={notification.templateKey}
            notification={notification}
            closeFuncRef={closeNotificationRef}
            markAsViewedEvent={MarkAsViewedEvent.OnHide}
            onClose={onClose}
            closeEvent={`close_${notification.templateKey}`}
            kind={NotificationKind.Rating}
        >
            <p>{trls[TrlKeys.description1]}</p>
            <p>{trls[TrlKeys.description2]}</p>
            <NotificationFooter>
                <Button
                    Element={Link}
                    appearance={ButtonAppearance.Filled}
                    scale={ButtonScale.Small}
                    onClick={sendLinkClickAnalytics}
                    target="_blank"
                    to={`https://rating.hh.ru/history/rating2024/?utm_source=hh.ru&utm_medium=banner&utm_campaign=notification_rating2024&utm_term=${userType}`}
                >
                    {trls[TrlKeys.link]}
                </Button>
            </NotificationFooter>
        </SynchronizedNotification>
    );
};

export default translation(EmployerHhRatingResults);
