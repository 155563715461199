import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface BrandSnippet {
    id: string;
    name: string;
}

export interface BrandSnippetData extends BrandSnippets {
    id: string | null;
    expirationTime: string | null;
}

export interface BrandSnippets {
    remainingApplyCount: number;
    quota: number | null;
    templates: BrandSnippet[];
}

export default autoGeneratedReducer<BrandSnippets>({
    remainingApplyCount: 0,
    templates: [],
    quota: null,
});
