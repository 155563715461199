import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { ProductIdType, RegionIdType, BillingPlatform, ProductType } from 'src/models/price/product.types';
import { ServiceItemCode } from 'src/models/price/productCodes';

export interface Aspe {
    id: string;
    serviceEntry: ServiceItemCode;
    entriesCount: number;
    balance: number;
    billingPlatforms?: BillingPlatform[];
}

export interface EmployerService {
    productId: ProductIdType;
    employerServiceId: string;
    shortTitle: string;
    aspes: Aspe[];
    creationTime: string;
    expirationTime: string;
    activationTime: string;
    region: RegionIdType;
}

export interface PriceAddContacts {
    tabName: string;
    dbAccessUrl: string;
    purchaseUrl: string;
    activeAccesses: EmployerService[];
    selectedServiceId: string | null;
    contactsProduct: ProductType | null;
    initialContactsCount: number;
    addPublicationsAvailable: boolean;
    publicationsProduct: ProductType | null;
    initialPublicationsCount: number;
    contactsSubmitting: boolean;
    publicationsSubmitting: boolean;
}

const initialState: PriceAddContacts = {
    tabName: '',
    dbAccessUrl: '',
    purchaseUrl: '',
    activeAccesses: [],
    selectedServiceId: null,
    contactsProduct: null,
    initialContactsCount: 1,
    addPublicationsAvailable: false,
    publicationsProduct: null,
    initialPublicationsCount: 1,
    contactsSubmitting: false,
    publicationsSubmitting: false,
};

const SET_SELECTED_SERVICE_ID = 'priceAddContacts/setSelectedServiceId';
const SET_CONTACTS_PRODUCT = 'priceAddContacts/setContactsProduct';
const SET_PUBLICATIONS_PRODUCT = 'priceAddContacts/setPublicationsProduct';
const SET_CONTACTS_SUBMITTING = 'priceAddContacts/setContactsSubmitting';
const SET_PUBLICATIONS_SUBMITTING = 'priceAddContacts/setPublicationsSubmitting';

interface PayloadTypes {
    [SET_SELECTED_SERVICE_ID]: string;
    [SET_CONTACTS_PRODUCT]: ProductType | null;
    [SET_PUBLICATIONS_PRODUCT]: ProductType | null;
    [SET_CONTACTS_SUBMITTING]: boolean;
    [SET_PUBLICATIONS_SUBMITTING]: boolean;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const setSelectedServiceId = actionCreator(SET_SELECTED_SERVICE_ID);
export const setContactsProduct = actionCreator(SET_CONTACTS_PRODUCT);
export const setPublicationsProduct = actionCreator(SET_PUBLICATIONS_PRODUCT);
export const setContactsSubmitting = actionCreator(SET_CONTACTS_SUBMITTING);
export const setPublicationsSubmitting = actionCreator(SET_PUBLICATIONS_SUBMITTING);

export default createReducer<PriceAddContacts, PayloadTypes>(initialState, {
    [SET_SELECTED_SERVICE_ID]: (state, { payload }) => {
        return { ...state, selectedServiceId: payload };
    },
    [SET_CONTACTS_PRODUCT]: (state, { payload }) => {
        return { ...state, contactsProduct: payload };
    },
    [SET_PUBLICATIONS_PRODUCT]: (state, { payload }) => {
        return { ...state, publicationsProduct: payload };
    },
    [SET_CONTACTS_SUBMITTING]: (state, { payload }) => {
        return { ...state, contactsSubmitting: payload };
    },
    [SET_PUBLICATIONS_SUBMITTING]: (state, { payload }) => {
        return { ...state, publicationsSubmitting: payload };
    },
});
