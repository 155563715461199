import { FC, useContext, useEffect } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { useChatik } from '@hh.ru/chatik-integration';
import { websocket } from '@hh.ru/chatik-integration/lib/Websocket';
import { BubbleRoundFilledSize24, BubbleRoundOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import IsDashboardContext from 'src/components/SupernovaMainMenu/IsDashboardContext';
import SupernovaActionLink from 'src/components/SupernovaMainMenu/SupernovaActionLink';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import WebcallDynamicActivator from 'src/components/Webcall/WebcallDynamicActivator';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';

const ChatikActivator: FC<NavItem> = ({ name, analytics }) => {
    const isDashboard = useContext(IsDashboardContext);
    const unreadCount = useSelector(({ chatikCounters }) => chatikCounters.unreadCount);
    const { isChatikOpen, closeChatik, openChatik } = useChatik();
    const additionalCheck = useSelector(({ additionalCheck }) => additionalCheck);

    const handleToggleChatik = () => {
        if (isChatikOpen) {
            closeChatik();

            return;
        }

        openChatik({ hhtmFromLabel: 'header', view: 'medium' });
        Analytics.sendHHEventButtonClick('chat_list_header', { unreadChatCount: unreadCount });
    };

    useEffect(() => {
        websocket.on('chat_message_create', (eventData) => {
            if (!isChatikOpen && eventData.actions?.find(({ type }) => type === 'FORCE_OPEN')) {
                openChatik({ chatId: eventData.chatId, view: 'medium' });
            }
        });
    }, [isChatikOpen, openChatik]);

    if (additionalCheck) {
        return null;
    }

    return (
        <SupernovaNaviItem
            className={classnames('supernova-navi-item_chatik', {
                'supernova-navi-item_dashboard': isDashboard,
                'supernova-navi-item_chatik-active': isChatikOpen,
            })}
            data-qa="chatik-activator-navi-item"
        >
            {/* так как звонок может быть из чата готовим звонилку рядом с чатиком*/}
            <WebcallDynamicActivator />
            <SupernovaActionLink
                name={name}
                badgeCount={unreadCount || undefined}
                showBadge={!!unreadCount}
                analytics={analytics}
                active={isChatikOpen}
                activeIcon={<BubbleRoundFilledSize24 />}
                icon={<BubbleRoundOutlinedSize24 />}
                onClick={handleToggleChatik}
                data-qa="chatik-activator"
            />
        </SupernovaNaviItem>
    );
};

export default ChatikActivator;
