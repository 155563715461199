import { Button, Badge, HSpacing } from '@hh.ru/magritte-ui';
import { FunnelOutlinedSize16, FunnelOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import BlokoBadge, { BadgeAppearance } from 'bloko/blocks/badge';
import BlokoButton, { ButtonAppearance, ButtonType } from 'bloko/blocks/button';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { SearchQueries, SEARCH_QUERY_KEYS } from 'src/models/search/resume/queries';
import { CriteriaKey } from 'src/types/search/common/criteria';

import QuerySettingsMobile from 'src/components/ResumeAdvancedSearch/SearchQueries/Magritte/QuerySettingsMobile';
import QuerySettingsXsModal from 'src/components/ResumeAdvancedSearch/SearchQueries/QuerySettingsXsModal';

import styles from './search-query.less';

const TrlKeys = {
    filtersButtonText: 'search.resume.multisearch.fieldFilters.button',
};

interface QuerySettingsXsButtonProps {
    query: SearchQueries;
    setQuery: (value: SearchQueries) => void;
    autoInvite?: boolean;
    isMagritte?: boolean;
}

const QuerySettingsXsButton: TranslatedComponent<QuerySettingsXsButtonProps> = ({
    trls,
    query,
    setQuery,
    autoInvite,
    isMagritte,
}) => {
    const [modalIsVisible, showModal, hideModal] = useOnOffState(false);

    const searchQueryDefault = useSelectorNonNullable((state) => state.searchQueries.default);
    const settingsCount = [...SEARCH_QUERY_KEYS, ...(autoInvite ? [CriteriaKey.ExpIndustry] : [])].reduce(
        (count, name) => {
            if (query[name].toString() === searchQueryDefault[name].toString()) {
                return count;
            }
            return count + 1;
        },
        0
    );

    return isMagritte ? (
        <>
            <Button
                mode="secondary"
                style="neutral"
                onClick={showModal}
                icon={<FunnelOutlinedSize24 />}
                aria-label={trls[TrlKeys.filtersButtonText]}
                hideLabel={true}
                badge={settingsCount ? <Badge size="small">{String(settingsCount)}</Badge> : undefined}
            />
            <QuerySettingsMobile
                isVisible={modalIsVisible}
                onClose={hideModal}
                currentQuery={query}
                setCurrentQuery={setQuery}
            />
        </>
    ) : (
        <div className={styles.settingsButtonWrapper}>
            <BlokoButton appearance={ButtonAppearance.Filled} type={ButtonType.Button} stretched onClick={showModal}>
                <BlokoIconReplaceContainer>
                    <FunnelOutlinedSize16 initial="primary" />
                </BlokoIconReplaceContainer>
                {settingsCount > 0 && (
                    <>
                        <HSpacing default={8} />
                        <BlokoBadge value={settingsCount} appearance={BadgeAppearance.Primary} />
                    </>
                )}
            </BlokoButton>
            <QuerySettingsXsModal
                currentQuery={query}
                setCurrentQuery={setQuery}
                isVisible={modalIsVisible}
                onClose={hideModal}
                autoInvite={autoInvite}
            />
        </div>
    );
};

export default translation(QuerySettingsXsButton);
