import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { CountryCode } from 'src/models/countryId';
import { CurrencyType } from 'src/models/currencies.types';
import { EmployerState } from 'src/models/employerStatus';
import { PartyType } from 'src/models/partyType';
import { PayerAgreement } from 'src/models/payerForm.types';
import { PaymentType } from 'src/models/paymentType';

export enum ChangeActivationTypeErrorCode {
    CartNotFound = 'CART_NOT_FOUND',
    UnknownActivationType = 'UNKNOWN_ACTIVATION_TYPE',
    CartActivationTypeIsNotChangeable = 'CART_ACTIVATION_TYPE_IS_NOT_CHANGEABLE',
}
interface Employer {
    maxCustomDiscountPercent: number;
    currency: CurrencyType;
    invoiceUnavailable?: boolean;
    availableWithoutBlockedMoney?: number;
}

interface Seller {
    sellerId: number;
    sellerName: string;
}

export interface ChangeActivationTypeError {
    message: ChangeActivationTypeErrorCode;
}

export interface ChangeActivationTypeErrorResponse {
    error?: ChangeActivationTypeError;
}

export interface OrganisationType {
    organisationType: PartyType;
    available: boolean;
}

export interface Country {
    code: CountryCode;
    name: string;
}
export interface PaymentTypeVisible {
    name: PaymentType;
    trl: string;
    forbidden: boolean;
    partyTypes: PartyType[];
}

export interface EmployerInvoicePurchase {
    backofficeEmployerId?: string;
    emailRequiredPaymentTypes?: Record<PartyType, PaymentType[]>;
    paymentTypesVisible: PaymentTypeVisible[];
    paymentTypes?: Partial<Record<PaymentType, string[]>>;
    hasPayers: boolean;
    employerId: number | null;
    employerCountry: CountryCode;
    organisationTypes: OrganisationType[];
    canGiveCustomDiscountForPurchase: boolean;
    purchaseUrls: string | null;
    showActivationBannerTop: boolean;
    showActivationBannerBottom: boolean;
    showActivationPriceAlert: boolean;
    employer: Employer;
    sellers: Seller[];
    employerState: EmployerState | null;
    selectedAgreement?: PayerAgreement;
}

const initialState: EmployerInvoicePurchase = {
    hasPayers: false,
    employerId: null,
    paymentTypes: {},
    paymentTypesVisible: [],
    employerCountry: CountryCode.Russia,
    organisationTypes: [],
    canGiveCustomDiscountForPurchase: false,
    purchaseUrls: null,
    showActivationBannerTop: false,
    showActivationBannerBottom: false,
    showActivationPriceAlert: false,
    employer: {
        maxCustomDiscountPercent: 0,
        currency: CurrencyType.RUR,
    },
    sellers: [],
    employerState: null,
};

const SET_SHOW_ACTIVATION_BANNER_TOP = 'SET_SHOW_ACTIVATION_BANNER_TOP';
const SET_SHOW_ACTIVATION_BANNER_BOTTOM = 'SET_SHOW_ACTIVATION_BANNER_BOTTOM';
const SET_SHOW_ACTIVATION_PRICE_ALERT = 'SET_SHOW_ACTIVATION_PRICE_ALERT';
const SET_SELECTED_AGREEMENT = 'SET_SELECTED_AGREEMENT';

interface PayloadTypes {
    [SET_SHOW_ACTIVATION_BANNER_TOP]: boolean;
    [SET_SHOW_ACTIVATION_BANNER_BOTTOM]: boolean;
    [SET_SHOW_ACTIVATION_PRICE_ALERT]: boolean;
    [SET_SELECTED_AGREEMENT]: PayerAgreement;
}
const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const setShowActivationBannerTop = actionCreator(SET_SHOW_ACTIVATION_BANNER_TOP);
export const setShowActivationBannerBottom = actionCreator(SET_SHOW_ACTIVATION_BANNER_BOTTOM);
export const setShowActivationPriceAlert = actionCreator(SET_SHOW_ACTIVATION_PRICE_ALERT);
export const setSelectedAgreement = actionCreator(SET_SELECTED_AGREEMENT);

export default createReducer<EmployerInvoicePurchase, PayloadTypes>(initialState, {
    [SET_SHOW_ACTIVATION_BANNER_TOP]: (state, { payload }) => {
        return { ...state, showActivationBannerTop: payload };
    },
    [SET_SHOW_ACTIVATION_BANNER_BOTTOM]: (state, { payload }) => {
        return { ...state, showActivationBannerBottom: payload };
    },
    [SET_SHOW_ACTIVATION_PRICE_ALERT]: (state, { payload }) => {
        return { ...state, showActivationPriceAlert: payload };
    },
    [SET_SELECTED_AGREEMENT]: (state, { payload }) => {
        return { ...state, selectedAgreement: payload };
    },
});
