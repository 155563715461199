import { autoGeneratedReducer, makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { LoadingState } from 'src/models/loadingState';

export enum ComparisonResult {
    LOWER = 'LOWER',
    HIGHER = 'HIGHER',
}

export interface Point {
    date: string;
    viewCount: number;
    responseCount: number;
    /**
     * Фейковая точка для расширения графика
     */
    isFake?: boolean;
}

export enum VacancyPublicationType {
    Standard = 'STANDARD',
    StandardPlus = 'STANDARD_PLUS',
    Premium = 'PREMIUM',
    Free = 'FREE',
    StandardAnonymous = 'STANDARD_ANONYMOUS',
    StandardPlusAnonymous = 'STANDARD_PLUS_ANONYMOUS',
    PremiumAnonymous = 'PREMIUM_ANONYMOUS',
    StandardAdvertising = 'STANDARD_ADVERTISING',
    PremiumAdvertising = 'PREMIUM_ADVERTISING',
    ZPAnonymous = 'ZP_ANONYMOUS',
    ZPDemo = 'ZP_DEMO',
    ZPPromo = 'ZP_PROMO',
    ZPBusiness = 'ZP_BUSINESS',
    ZPSprint = 'ZP_SPRINT',
    PFP = 'PFP',
}

export interface Publication {
    prolongations: string[];
    startDate: string;
    publicationType: VacancyPublicationType;
}

export interface VacancyConversionStatistics {
    startDate: string;
    endDate: string;
    viewCount: number;
    searchCount: number;
    responseCount: number;
    vacancyId: number;
    viewToResponseConversion: number;
    viewToResponseConversionComparison: {
        comparisonResult: ComparisonResult;
        value: number;
    } | null;
    points: Point[];
    publications: Publication[];
}

interface VacancyConversionStatisticsState {
    loadingState: LoadingState;
    data: VacancyConversionStatistics | null;
}

const INITIAL_STATE = {
    loadingState: LoadingState.None,
    data: null,
};

export const setVacancyConversionStatistics = makeSetStoreField('vacancyConversionStatistics');

export default autoGeneratedReducer<VacancyConversionStatisticsState>(INITIAL_STATE);
