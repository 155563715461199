import { ComponentType, useMemo } from 'react';

import { StaticDataFetcherItem, useStaticDataProvider } from '@hh.ru/magritte-common-data-provider';
import { Select } from '@hh.ru/magritte-ui-select';

import translation from 'src/components/translation';

import { ImportResume } from 'src/components/ImportResumeModal/types';

const TrlKeys = {
    placeholder: 'zp.importResume.resume.placeholder',
};

interface ResumeListProps {
    resumes: ImportResume[];
    value: string;
    onChange: (value: string) => void;
}

const ResumeList: ComponentType<ResumeListProps> = translation(({ trls, resumes, value, onChange }) => {
    const items: StaticDataFetcherItem[] = useMemo(
        () => resumes.map((resume) => ({ value: resume.value, text: resume.label })),
        [resumes]
    );
    const provider = useStaticDataProvider(items);
    return (
        <Select
            type="radio"
            name={'resumes'}
            value={items.find((item) => item.value === value)}
            onChange={(newValue) => onChange(newValue?.value ?? '')}
            triggerProps={{
                size: 'medium',
                label: trls[TrlKeys.placeholder],
                stretched: true,
                'data-qa': 'import_resume-resume-select',
            }}
            dataProvider={provider}
        />
    );
});

export default ResumeList;
