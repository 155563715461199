import { useMemo } from 'react';

import { BarsOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import CompositeSelection from 'bloko/blocks/compositeSelection';
import InputText from 'bloko/blocks/inputText';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import getConversionType from 'bloko/common/getConversionType';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';
import { format } from 'bloko/common/trl';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey } from 'src/types/search/common/criteria';

const TrlKeys = {
    title: 'fied.header.title.mobile',
    selectIndustry: 'resumesSearch.industry.mobile.any',
    oneIndustry: 'resumesSearch.industry.mobile.one',
    someIndustry: 'resumesSearch.industry.mobile.some',
    manyIndustry: 'resumesSearch.industry.mobile.many',
    save: 'treeselector.save',
    cancel: 'treeselector.cancel',
    placeholder: 'treeselector.quicksearch',
    notFound: 'treeselector.notFound',
};

interface IndustryXsSelectorProps {
    values: string[];
    setValues: (value: string[]) => void;
}

const IndustryXsSelector: TranslatedComponent<IndustryXsSelectorProps> = ({ trls, values, setValues }) => {
    const industryTree = useSelector((state) => state.industriesTree);
    const industryTreeTreeCollection = useMemo(() => fromTree(industryTree), [industryTree]);

    const defaultValue = useSelector((state) => state.searchQueries?.default[CriteriaKey.ExpIndustry]) || [];
    const selectedValues = values.toString() === defaultValue.toString() ? [] : values;

    const selectedValuesTrl = useMemo(() => {
        if (selectedValues.length === 0) {
            return trls[TrlKeys.selectIndustry];
        }
        const conversionTrl = getConversionType({
            one: trls[TrlKeys.oneIndustry],
            some: trls[TrlKeys.someIndustry],
            many: trls[TrlKeys.manyIndustry],
            value: selectedValues.length,
        });
        return format(conversionTrl, { '{0}': selectedValues.length });
    }, [selectedValues.length, trls]);

    return (
        <>
            <Text strong>{trls[TrlKeys.title]}</Text>
            <VSpacing base={3} />
            <CompositeSelection
                collection={industryTreeTreeCollection}
                value={selectedValues}
                onChange={(values) => setValues(values.length === 0 ? defaultValue : values)}
                title={trls[TrlKeys.title]}
                trl={{
                    submit: trls[TrlKeys.save],
                    cancel: trls[TrlKeys.cancel],
                    searchPlaceholder: trls[TrlKeys.placeholder],
                    notFound: trls[TrlKeys.notFound],
                }}
            >
                {({ showTreeSelectorPopup, renderTagList }) => (
                    <>
                        <InputText
                            onClick={() => showTreeSelectorPopup()}
                            value={selectedValuesTrl}
                            icon={
                                <BlokoIconReplaceContainer>
                                    <BarsOutlinedSize16 initial="secondary" highlighted="primary" />
                                </BlokoIconReplaceContainer>
                            }
                        />
                        <VSpacing base={1} />
                        {renderTagList({ stretched: true, removable: true })}
                    </>
                )}
            </CompositeSelection>
        </>
    );
};

export default translation(IndustryXsSelector);
