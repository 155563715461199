import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { SearchClusterMap, ClusterKey } from 'src/types/search/common/clusters';
import { VacancySearchItem } from 'src/types/search/vacancy/card';
import { Criteria } from 'src/types/search/vacancy/criteria';
import { VacancySearchQueries } from 'src/types/search/vacancy/queries';

import { SearchSettings } from 'src/models/search/common/settings';

export const MIN_ANONYMOUS_SUITABLE_VACANCIES_COUNT = 5;
export const MIN_APPLICANT_SUITABLE_VACANCIES_COUNT = 1;

export interface SuitableVacancies {
    itemsOnPage: number;
    resultsFound: number;
    totalPages: number;
    lastActivityTimeByHHID: Record<string, { dt: string }>;
    vacancies: VacancySearchItem[];
    areaIds: string[];
    // tempexp_PORTFOLIO-30605_start
    queries?: Partial<VacancySearchQueries>;
    criteria?: Criteria;
    searchClusters: SearchClusterMap;
    searchClustersOrder: Record<ClusterKey, string[]>;
    searchSettings: SearchSettings;
    // // tempexp_PORTFOLIO-30605_end
}

export default autoGeneratedReducer<SuitableVacancies>(null);
