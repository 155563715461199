import { ComponentType } from 'react';

import { ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

import { UserNotificationProps } from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import ApplicantCalling from 'src/components/SupernovaMainMenu/UserNotifications/variants/ApplicantCalling';
import ApplicantFindJob from 'src/components/SupernovaMainMenu/UserNotifications/variants/ApplicantFindJob';
import AutoInviteDisabled from 'src/components/SupernovaMainMenu/UserNotifications/variants/AutoInviteDisabled';
import BillingCardsToCardId from 'src/components/SupernovaMainMenu/UserNotifications/variants/BillingCardsToCardId';
import BillingCartActivation from 'src/components/SupernovaMainMenu/UserNotifications/variants/BillingCartActivation';
import BillingInvoiceFinish from 'src/components/SupernovaMainMenu/UserNotifications/variants/BillingInvoiceFinish';
import BrandingTriggerSupernovaNotification from 'src/components/SupernovaMainMenu/UserNotifications/variants/BrandingTriggerSupernovaNotification';
import CallTracking from 'src/components/SupernovaMainMenu/UserNotifications/variants/CallTracking';
import CareerConsult from 'src/components/SupernovaMainMenu/UserNotifications/variants/CareerConsult';
import CareerConsultWaitingForCall from 'src/components/SupernovaMainMenu/UserNotifications/variants/CareerConsultWaitingForCall';
import CompleteResume from 'src/components/SupernovaMainMenu/UserNotifications/variants/CompleteResume';
import CompleteResumeWaitingForCall from 'src/components/SupernovaMainMenu/UserNotifications/variants/CompleteResumeWaitingForCall';
import DefaultInvariant from 'src/components/SupernovaMainMenu/UserNotifications/variants/DefaultInvariant';
import Employer2fa from 'src/components/SupernovaMainMenu/UserNotifications/variants/Employer2fa';
import EmployerChangeEmailByManager from 'src/components/SupernovaMainMenu/UserNotifications/variants/EmployerChangeEmailByManager';
import EmployerChangeEmailReminder from 'src/components/SupernovaMainMenu/UserNotifications/variants/EmployerChangeEmailReminder';
import EmployerLoyaltySurvey from 'src/components/SupernovaMainMenu/UserNotifications/variants/EmployerLoyaltySurvey';
import EmployerReviewCanEnable from 'src/components/SupernovaMainMenu/UserNotifications/variants/EmployerReviewCanEnable';
import EmployerReviewFeedbackCreated from 'src/components/SupernovaMainMenu/UserNotifications/variants/EmployerReviewFeedbackCreated';
import EmployerSwitchToRoundLogo from 'src/components/SupernovaMainMenu/UserNotifications/variants/EmployerSwitchToRoundLogo';
import EmployerVerification from 'src/components/SupernovaMainMenu/UserNotifications/variants/EmployerVerification';
import HHRating from 'src/components/SupernovaMainMenu/UserNotifications/variants/HHRating';
import HRSpace from 'src/components/SupernovaMainMenu/UserNotifications/variants/HRSpace';
import InterviewPracticePaid from 'src/components/SupernovaMainMenu/UserNotifications/variants/InterviewPractice';
import InterviewPracticeWaitingForCall from 'src/components/SupernovaMainMenu/UserNotifications/variants/InterviewPracticeWaitingForCall';
import KingdomOfVacancy from 'src/components/SupernovaMainMenu/UserNotifications/variants/KingdomOfVacancy';
import NegotiationInterview from 'src/components/SupernovaMainMenu/UserNotifications/variants/NegotiationInterview';
import ResumeRenewalExpired from 'src/components/SupernovaMainMenu/UserNotifications/variants/ResumeRenewalExpired';
import ResumeRenewalSoonExpires from 'src/components/SupernovaMainMenu/UserNotifications/variants/ResumeRenewalSoonExpires';
import SetkaPromo from 'src/components/SupernovaMainMenu/UserNotifications/variants/SetkaPromo';
import VacancyAutoProlongation from 'src/components/SupernovaMainMenu/UserNotifications/variants/VacancyAutoProlongation';
import VacancyAutoPublication from 'src/components/SupernovaMainMenu/UserNotifications/variants/VacancyAutoPublication';
import VacancyAutoUpdate from 'src/components/SupernovaMainMenu/UserNotifications/variants/VacancyAutoUpdate';
import VacancyBlocked from 'src/components/SupernovaMainMenu/UserNotifications/variants/VacancyBlocked';
import VacancyDraftPublicationReady from 'src/components/SupernovaMainMenu/UserNotifications/variants/VacancyDraftPublicationReady';
import VacancyScheduledPublication from 'src/components/SupernovaMainMenu/UserNotifications/variants/VacancyScheduledPublication';
import YouthSpecailProject from 'src/components/SupernovaMainMenu/UserNotifications/variants/YouthSpecialProject';

type UserNotificationConfig = Record<string, ComponentType<UserNotificationProps>>;

const userNotificationsConfig: UserNotificationConfig = {
    [ComplexUserNotificationsTemplateKey.ApplicantCallTracking]: ApplicantCalling,
    [ComplexUserNotificationsTemplateKey.ApplicantWorkAddress]: ApplicantFindJob,
    [ComplexUserNotificationsTemplateKey.BillingCartInsufficientFunds]: BillingCardsToCardId,
    [ComplexUserNotificationsTemplateKey.BillingCartIrrelevantStatus]: BillingCardsToCardId,
    [ComplexUserNotificationsTemplateKey.BillingCartError]: BillingCardsToCardId,
    [ComplexUserNotificationsTemplateKey.BillingCartActivated]: BillingInvoiceFinish,
    [ComplexUserNotificationsTemplateKey.BillingCartActivationReady]: BillingInvoiceFinish,
    [ComplexUserNotificationsTemplateKey.BillingDelayedCartActivationForbidden]: BillingCartActivation,
    [ComplexUserNotificationsTemplateKey.BillingDelayedCartActivationExpired]: BillingCartActivation,
    // == Branding start ==
    [ComplexUserNotificationsTemplateKey.FillEmployerPageConstructor]: BrandingTriggerSupernovaNotification,
    [ComplexUserNotificationsTemplateKey.FillEmployerVacancyPageConstructor]: BrandingTriggerSupernovaNotification,
    [ComplexUserNotificationsTemplateKey.BuyVacancyPageConstructor]: BrandingTriggerSupernovaNotification,
    [ComplexUserNotificationsTemplateKey.BuyEmployerPageConstructor]: BrandingTriggerSupernovaNotification,
    [ComplexUserNotificationsTemplateKey.SuggestionVacancyMakeup]: BrandingTriggerSupernovaNotification,
    [ComplexUserNotificationsTemplateKey.SuggestionEmployerMakeup]: BrandingTriggerSupernovaNotification,
    // == Branding end ==
    [ComplexUserNotificationsTemplateKey.CallTracking]: CallTracking,
    [ComplexUserNotificationsTemplateKey.CareerConsultPaid]: CareerConsult,
    [ComplexUserNotificationsTemplateKey.CareerConsultwaitingForCall]: CareerConsultWaitingForCall,
    [ComplexUserNotificationsTemplateKey.CompleteResumeWorkDone]: CompleteResume,
    [ComplexUserNotificationsTemplateKey.CompleteResumePaid]: CompleteResume,
    [ComplexUserNotificationsTemplateKey.CompleteResumeWaitingForCall]: CompleteResumeWaitingForCall,
    [ComplexUserNotificationsTemplateKey.Employer2fa]: Employer2fa,
    [ComplexUserNotificationsTemplateKey.EmployerReviewsCanEnable]: EmployerReviewCanEnable,
    [ComplexUserNotificationsTemplateKey.EmployerReviewFeedbackCreated]: EmployerReviewFeedbackCreated,
    [ComplexUserNotificationsTemplateKey.HHRatingRegistration]: HHRating,
    [ComplexUserNotificationsTemplateKey.HHRatingVoteInvitation]: HHRating,
    [ComplexUserNotificationsTemplateKey.HRSpacePromoApplicant]: HRSpace,
    [ComplexUserNotificationsTemplateKey.HRSpacePromoEmployer]: HRSpace,
    [ComplexUserNotificationsTemplateKey.HRSpacePromoITEmployer]: HRSpace,
    [ComplexUserNotificationsTemplateKey.InterviewPracticePaid]: InterviewPracticePaid,
    [ComplexUserNotificationsTemplateKey.InterviewPracticeForCall]: InterviewPracticeWaitingForCall,
    [ComplexUserNotificationsTemplateKey.NegotiationInterview]: NegotiationInterview,
    [ComplexUserNotificationsTemplateKey.PricesIncrease]: DefaultInvariant,
    [ComplexUserNotificationsTemplateKey.ResumeBlocked]: DefaultInvariant,
    [ComplexUserNotificationsTemplateKey.ResumeTargetEmployerDiscount]: DefaultInvariant,
    [ComplexUserNotificationsTemplateKey.ApplicantJobSearchStatus]: DefaultInvariant,
    [ComplexUserNotificationsTemplateKey.ResumeRenewalExpired]: ResumeRenewalExpired,
    [ComplexUserNotificationsTemplateKey.ResumeRenewalSoonExpires]: ResumeRenewalSoonExpires,
    [ComplexUserNotificationsTemplateKey.UserSupportNewEvents]: DefaultInvariant,
    [ComplexUserNotificationsTemplateKey.VideoCall]: DefaultInvariant,
    [ComplexUserNotificationsTemplateKey.EmployerYearResult]: DefaultInvariant,
    [ComplexUserNotificationsTemplateKey.AutoSearchVacancies]: DefaultInvariant,
    [ComplexUserNotificationsTemplateKey.VacancyAutoProlongation]: VacancyAutoProlongation,
    [ComplexUserNotificationsTemplateKey.VacancyAutoPublicationUnknown]: VacancyAutoPublication,
    [ComplexUserNotificationsTemplateKey.VacancyAutoPublicationValidationFailure]: VacancyAutoPublication,
    [ComplexUserNotificationsTemplateKey.VacancyAutoPublicationNoPublication]: VacancyAutoPublication,
    [ComplexUserNotificationsTemplateKey.VacancyAutoPublicationNoPermission]: VacancyAutoPublication,
    [ComplexUserNotificationsTemplateKey.VacancyAutoPublicationFailure]: VacancyAutoPublication,
    [ComplexUserNotificationsTemplateKey.VacancyAutoPublicationSuccesses]: VacancyAutoPublication,
    [ComplexUserNotificationsTemplateKey.VacancyAutoPublicationSuccess]: VacancyAutoPublication,
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateSuccesses]: VacancyAutoUpdate,
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateSuccess]: VacancyAutoUpdate,
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoPublications]: VacancyAutoUpdate,
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoQuota]: VacancyAutoUpdate,
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoPermissions]: VacancyAutoUpdate,
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoPublication]: VacancyAutoUpdate,
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoQuotas]: VacancyAutoUpdate,
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoPermission]: VacancyAutoUpdate,
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoQuotaFailure]: VacancyAutoUpdate,
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoQuotaFailures]: VacancyAutoUpdate,
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoPublicationFailure]: VacancyAutoUpdate,
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoPublicationFailures]: VacancyAutoUpdate,
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoPermissionFailure]: VacancyAutoUpdate,
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoPermissionFailures]: VacancyAutoUpdate,
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateTooMuchFailure]: VacancyAutoUpdate,
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateTooMuchFailures]: VacancyAutoUpdate,
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationNoQuotasFailure]: VacancyScheduledPublication,
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationNoPermissionFailure]: VacancyScheduledPublication,
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationNoPublicationFailure]: VacancyScheduledPublication,
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationValidationFailure]: VacancyScheduledPublication,
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationUnknownFailure]: VacancyScheduledPublication,
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationSuccesses]: VacancyScheduledPublication,
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationSuccess]: VacancyScheduledPublication,
    [ComplexUserNotificationsTemplateKey.EmployerLoyaltySurvey]: EmployerLoyaltySurvey,
    [ComplexUserNotificationsTemplateKey.VacancyDraftPublicationReady]: VacancyDraftPublicationReady,
    [ComplexUserNotificationsTemplateKey.ChangeEmployerManagerEmailChangedNotification]: EmployerChangeEmailByManager,
    [ComplexUserNotificationsTemplateKey.ChangeEmployerManagerEmailConfirmationReminder]: EmployerChangeEmailReminder,
    [ComplexUserNotificationsTemplateKey.EmployerVerified]: EmployerVerification,
    [ComplexUserNotificationsTemplateKey.EmployerNotVerified]: EmployerVerification,
    [ComplexUserNotificationsTemplateKey.VacancyBlockedDueReplacement]: VacancyBlocked,
    [ComplexUserNotificationsTemplateKey.VacancyBlockedDueModerator]: VacancyBlocked,
    [ComplexUserNotificationsTemplateKey.VacancyBlockedDueTraining]: VacancyBlocked,
    [ComplexUserNotificationsTemplateKey.VacancyBlockedDueBadDescription]: VacancyBlocked,
    [ComplexUserNotificationsTemplateKey.VacancyBlockedDuePaidServices]: VacancyBlocked,
    [ComplexUserNotificationsTemplateKey.VacancyBlockedDueCaptionMissing]: VacancyBlocked,
    [ComplexUserNotificationsTemplateKey.VacancyBlockedDueRelocateFromBelarus]: VacancyBlocked,
    [ComplexUserNotificationsTemplateKey.VacancyBlockedDueTwoInOne]: VacancyBlocked,
    [ComplexUserNotificationsTemplateKey.AutoInviteDisabled]: AutoInviteDisabled,
    [ComplexUserNotificationsTemplateKey.KingdomOfVacancy]: KingdomOfVacancy,
    [ComplexUserNotificationsTemplateKey.SetkaPromo]: SetkaPromo,
    [ComplexUserNotificationsTemplateKey.YouthSpecialProject]: YouthSpecailProject,
    [ComplexUserNotificationsTemplateKey.EmployerSwitchToRoundLogo]: EmployerSwitchToRoundLogo,
};

export default userNotificationsConfig;
