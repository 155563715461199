export const CriteriaKey = {
    AcceptTemporary: 'accept_temporary',
    AgeFrom: 'age_from',
    AgeTo: 'age_to',
    Area: 'area',
    BottomLeftLat: 'bottom_left_lat',
    BottomLeftLng: 'bottom_left_lng',
    Citizenship: 'citizenship',
    Clusters: 'clusters',
    ControlFlag: 'control_flag',
    CurrencyCode: 'currency_code',
    CurrentEmployerId: 'current_employer_id',
    DateFrom: 'date_from',
    DateTo: 'date_to',
    Department: 'department',
    District: 'district',
    DriverLicenseTypes: 'driver_license_types',
    Education: 'education',
    EducationLevel: 'education_level',
    EmployerId: 'employer_id',
    EmployerManagerId: 'employer_manager_id',
    Employment: 'employment',
    EmploymentForm: 'employment_form',
    ExcludedText: 'excluded_text',
    ExpCompanySize: 'exp_company_size',
    Experience: 'experience',
    ExpIndustry: 'exp_industry',
    ExpPeriod: 'exp_period',
    FilterExpIndustry: 'filter_exp_industry',
    FilterExpPeriodForIndustry: 'filter_exp_period',
    Folder: 'folder',
    Gender: 'gender',
    GeocodeType: 'geocode_type',
    Geohash: 'geohash',
    Height: 'height',
    HiddenFilters: 'hidden_filters',
    Industry: 'industry',
    Internship: 'internship',
    IsAutosearch: 'L_is_autosearch',
    IsDefaultArea: 'isDefaultArea',
    ItemsOnPage: 'items_on_page',
    JobSearchStatus: 'job_search_status',
    Label: 'label',
    Language: 'language',
    Lenient: 'L_lenient',
    Logic: 'logic',
    Metro: 'metro',
    NoMagic: 'no_magic',
    OnlyWithAge: 'only_with_age',
    OnlyWithGender: 'only_with_gender',
    OnlyWithPhoto: 'only_with_photo',
    OnlyWithSalary: 'only_with_salary',
    OrderBy: 'order_by',
    Page: 'page',
    PartTime: 'part_time',
    Pos: 'pos',
    Precision: 'precision',
    ProfessionalRole: 'professional_role',
    RecommendedByUid: 'recommended_by_uid',
    Relocation: 'relocation',
    Resume: 'resume',
    Salary: 'salary',
    SalaryFrom: 'salary_from',
    SalaryTo: 'salary_to',
    SavedSearchId: 'saved_search_id',
    Schedule: 'schedule',
    SearchDebug: 'search_debug',
    SearchField: 'search_field',
    SearchPeriod: 'search_period',
    SearchSessionId: 'search_session_id',
    Skill: 'skill',
    Specialization: 'specialization',
    Text: 'text',
    TopRightLat: 'top_right_lat',
    TopRightLng: 'top_right_lng',
    University: 'university',
    VacancyId: 'vacancy_id',
    VerifiedLanguages: 'verified_languages',
    VerifiedSkills: 'verified_skills',
    Width: 'width',
    WorkFormat: 'work_format',
    WorkingHours: 'working_hours',
    WorkScheduleByDays: 'work_schedule_by_days',
    WorkTicket: 'work_ticket',
} as const;

export type CriteriaKey = (typeof CriteriaKey)[keyof typeof CriteriaKey];

export enum ExperienceOption {
    Between1And3 = 'between1And3',
    Between3And6 = 'between3And6',
    MoreThan6 = 'moreThan6',
    NoExperience = 'noExperience',
}

export enum FilterExpPeriodOption {
    AllTime = 'all_time',
    LastYear = 'last_year',
    LastThreeYears = 'last_three_years',
    LastSixYears = 'last_six_years',
}

export enum OrderByOption {
    PublicationTime = 'publication_time',
    SalaryDesc = 'salary_desc',
    SalaryAsc = 'salary_asc',
    Relevance = 'relevance',
}

export enum PartTimeOption {
    EmploymentProject = 'employment_project',
    EmploymentPart = 'employment_part',
    TemporaryJobTrue = 'temporary_job_true',
    FromFourToSixHoursInADay = 'from_four_to_six_hours_in_a_day',
    OnlySaturdayAndSunday = 'only_saturday_and_sunday',
    StartAfterSixteen = 'start_after_sixteen',
}

export enum ScheduleOption {
    FullDay = 'fullDay',
    Shift = 'shift',
    Flexible = 'flexible',
    Remote = 'remote',
    FlyInFlyOut = 'flyInFlyOut',
}

export enum SearchFieldOption {
    Name = 'name',
    CompanyName = 'company_name',
    Description = 'description',
}
