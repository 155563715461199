import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { Replace } from '@hh.ru/redux-spa-middleware';
import urlParser from 'bloko/common/urlParser';

import { isNumber } from 'Utils/Utils';
import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { AppStore } from 'src/app/store';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { CurrencyType } from 'src/models/currencies.types';
import {
    EmployerVacancySearch,
    replaceEmployerVacanciesSearchResult,
    setIsLoadingEmployerVacancies,
} from 'src/models/employerVacancySearch/index';
import {
    setOrderArea,
    setOrderDistrict,
    setOrderIndustry,
    setOrderMetro,
    setOrderProfRole,
    setOrderSubIndustry,
} from 'src/models/search/common/clustersOrder';
import { SearchClusterMap, ClusterKey } from 'src/types/search/common/clusters';
import UserLabel from 'src/utils/constants/userLabels';
import fetcher from 'src/utils/fetcher';

const EMPLOYER_VACACNCY_SEARCH = '/shards/employerview/employer_vacancy_search';
const userLabelsForVacanciesAction = makeSetStoreField('userLabelsForVacancies');
const activeEmployerVacancyCountAction = makeSetStoreField('activeEmployerVacancyCount');
const searchLoadingAction = makeSetStoreField('searchLoading');
const searchPreferenceAction = makeSetStoreField('searchPreference');
const searchClustersAction = makeSetStoreField('searchClusters');
const criteriaCurrencyCodeAction = makeSetStoreField('criteriaCurrencyCode');
const searchCountsAction = makeSetStoreField('searchCounts');

export const getCurrentPage = (employerVacancySearch: EmployerVacancySearch): number =>
    Number(employerVacancySearch?.paging?.pages.find(({ selected }) => selected)?.text ?? 0);

declare global {
    interface FetcherGetApi {
        [EMPLOYER_VACACNCY_SEARCH]: {
            queryParams: undefined;
            response: {
                employerVacancySearch: EmployerVacancySearch;
                userLabelsForVacancies: { [vacancyId: number]: UserLabel[] };
                counts?: number;
                searchClustersOrder: Record<ClusterKey, string[]>;
                searchClusters: SearchClusterMap;
                criteriaCurrencyCode: CurrencyType;
            };
        };
    }
}

interface Params {
    addNotification: AddNotification;
    replace: Replace;
    query: string;
    abortSignal?: AbortSignal;
    isMobile?: boolean;
}

type FetchEmployerVacancies = (params: Params) => ThunkAction<Promise<unknown>, AppStore, unknown, AnyAction>;

const fetchEmployerVacancies: FetchEmployerVacancies =
    ({ addNotification, replace, query, abortSignal, isMobile }) =>
    async (dispatch, getState) => {
        const location = getState().router.location;
        let searchResult = null;
        dispatch(setIsLoadingEmployerVacancies(true));
        try {
            searchResult = await fetcher.get(
                `${EMPLOYER_VACACNCY_SEARCH}?${query}` as typeof EMPLOYER_VACACNCY_SEARCH,
                {
                    params: undefined,
                    signal: abortSignal,
                }
            );
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
            return;
        } finally {
            dispatch(setIsLoadingEmployerVacancies(false));
            dispatch(searchLoadingAction(false));
            isMobile && dispatch(searchPreferenceAction({ isShown: false }));
        }
        if (searchResult) {
            const {
                searchClusters,
                searchClustersOrder,
                employerVacancySearch,
                userLabelsForVacancies,
                criteriaCurrencyCode,
            } = searchResult;

            isNumber(searchResult.counts) && dispatch(activeEmployerVacancyCountAction(searchResult.counts));

            dispatch(setOrderArea(searchClustersOrder?.[ClusterKey.Area] || []));
            dispatch(setOrderDistrict(searchClustersOrder?.[ClusterKey.District] || []));
            dispatch(setOrderMetro(searchClustersOrder?.[ClusterKey.Metro] || []));
            dispatch(setOrderProfRole(searchClustersOrder?.[ClusterKey.ProfessionalRole] || []));
            dispatch(setOrderIndustry(searchClustersOrder?.[ClusterKey.Industry] || []));
            dispatch(setOrderSubIndustry(searchClustersOrder?.[ClusterKey.SubIndustry] || []));
            dispatch(criteriaCurrencyCodeAction(criteriaCurrencyCode));
            dispatch(searchClustersAction(searchClusters));
            dispatch(userLabelsForVacanciesAction(userLabelsForVacancies));
            dispatch(replaceEmployerVacanciesSearchResult(employerVacancySearch));
            dispatch(searchCountsAction({ isLoad: false, value: employerVacancySearch?.resultsFound ?? 0 }));

            const newLocation = urlParser(location.pathname + location.search);
            const currentPage = getCurrentPage(employerVacancySearch);
            if (currentPage) {
                newLocation.params.page = getCurrentPage(employerVacancySearch);
                replace(newLocation.href, undefined, { cancelFetchingData: true });
            }
        }
    };

export default fetchEmployerVacancies;
