import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import BlokoLink from 'bloko/blocks/link';
import Cookies from 'bloko/common/Cookies';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import saveApplicantSubscriptions from 'src/api/applicant/saveSubscriptions';
import saveEmployerSubscriptions from 'src/api/employer/saveSubscriptions';
import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import paths from 'src/app/routePaths';
import { useNotification } from 'src/components/Notifications/Provider';
import subscribeFormSuccessNotification from 'src/components/Notifications/SubscribeForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import styles from './subscription-informer.less';

const TrlKeys = {
    accept: 'subscription.informer.accept',
    decline: 'subscription.informer.decline',
    title: 'subscription.informer.title',
    description: 'subscription.informer.description',
    hintText: 'subscription.informer.hint.text',
    hintConditionsLink: 'subscription.informer.hint.link.conditions',
    hintRightsLink: 'subscription.informer.hint.link.rights',
};

export const COOKIE_SUBSCRIPTION_INFORMER_NAME = 'subscription_informer_declined';

const SubscriptionInformer: TranslatedComponent = ({ trls }) => {
    const location = useLocation();
    const [isVisible, setIsVisible] = useState(true);
    const { addNotification } = useNotification();
    const {
        isShow: isShowSubscriptionInformer,
        applicantSubscriptions,
        employerSubscriptions,
    } = useSelector(({ subscriptionInformer }) => subscriptionInformer);
    const userType = useSelector(({ userType }) => userType);

    useEffect(() => {
        if ([paths.subscribe, paths.employerSubscription].includes(location.pathname)) {
            setIsVisible(false);
        }
    }, [location]);

    const handleDecline = () => {
        Cookies.set(COOKIE_SUBSCRIPTION_INFORMER_NAME, String(true), 24 * 30);
        setIsVisible(false);
    };

    const handleSubscribe = async () => {
        const isApplicant = userType === UserType.Applicant;
        const subscriptions = isApplicant
            ? {
                  ...applicantSubscriptions,
                  emailCheckboxes: [...applicantSubscriptions.emailCheckboxes, 'HHAPPLICANT_DIGEST'],
              }
            : {
                  ...employerSubscriptions,
                  emailCheckboxes: [...employerSubscriptions.emailCheckboxes, 'HHEMPLOYER_DIGEST'],
              };

        const saveSubscriptions = isApplicant ? saveApplicantSubscriptions : saveEmployerSubscriptions;
        try {
            await saveSubscriptions({ subscriptions });

            addNotification(subscribeFormSuccessNotification);
            setIsVisible(false);
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
        }
    };

    if (
        !isVisible ||
        !isShowSubscriptionInformer ||
        !(userType === UserType.Employer || userType === UserType.Applicant)
    ) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <ColumnsWrapper>
                <Column xs="4" s="8" m="12" l="16">
                    <div className={styles.content}>
                        <div>
                            <div className={styles.text}>{trls[TrlKeys.title]}</div>
                            <div className={styles.text}>{trls[TrlKeys.description]}</div>
                            <div className={styles.hint}>
                                {formatToReactComponent(trls[TrlKeys.hintText], {
                                    '{conditions}': (
                                        <BlokoLink disableVisited Element={Link} to="/article/31297" target="_blank">
                                            {trls[TrlKeys.hintConditionsLink]}
                                        </BlokoLink>
                                    ),
                                    '{rights}': (
                                        <BlokoLink
                                            disableVisited
                                            Element={Link}
                                            to="/article/30901#clause1"
                                            target="_blank"
                                        >
                                            {trls[TrlKeys.hintRightsLink]}
                                        </BlokoLink>
                                    ),
                                })}
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <Button onClick={handleSubscribe} kind={ButtonKind.Primary} stretched>
                                {trls[TrlKeys.accept]}
                            </Button>
                            <Button onClick={handleDecline} stretched>
                                {trls[TrlKeys.decline]}
                            </Button>
                        </div>
                    </div>
                </Column>
            </ColumnsWrapper>
        </div>
    );
};

export default translation(SubscriptionInformer);
