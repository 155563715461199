import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';
import { DataProviderItem } from 'bloko/blocks/suggest/types';

import { DistrictItem as MagritteDistrictItem } from 'src/components/AdvancedSearchFilters/Magritte/District/types';
import { MetroItem as MagritteMetroItem } from 'src/components/AdvancedSearchFilters/Magritte/Metro/types';
import { JobSearchStatus } from 'src/models/applicantUserStatuses';
import DriverLicenseOption from 'src/models/driverLicense.types';
import { EducationLevel } from 'src/models/resume/resumeCommon.types';
import { CriteriaKey, ExperienceOption, FilterExpPeriodOption } from 'src/types/search/common/criteria';
import { LabelOption } from 'src/types/search/resume/criteria';
import { EmploymentLower } from 'src/utils/constants/employment';
import { ScheduleCamelCase } from 'src/utils/constants/schedule';

interface Vacancy {
    vacancyId: number;
    name: string;
}

interface Resume {
    title: string;
    hash: string;
}

export interface MetroItem extends DataProviderItem {
    color?: string;
    line?: number;
}

export type DistrictItem = DataProviderItem;

export interface UniversityItem extends DataProviderItem {
    acronym?: string;
    synonyms?: string;
}

export enum ExclusionOption {
    ExcludeViewedByUserId = 'exclude_viewed_by_user_id',
    ExcludeViewedByEmployerId = 'exclude_viewed_by_employer_id',
    OnlyInResponses = 'only_in_responses',
}

interface CompanyShort {
    areaId: number;
    category: string;
    fromZarplata: boolean;
    id: number;
    mainEmployerId: number;
    name: string;
    organizationFormId: number;
    structureName: string;
}

export interface AdvancedSearch {
    [CriteriaKey.Citizenship]?: DataProviderItem[];
    [CriteriaKey.District]?: DistrictItem[];
    [CriteriaKey.DriverLicenseTypes]: DriverLicenseOption[];
    [CriteriaKey.EducationLevel]?: EducationLevel[];
    [CriteriaKey.Employment]: EmploymentLower[];
    [CriteriaKey.Experience]: ExperienceOption[];
    [CriteriaKey.FilterExpIndustry]?: string[];
    [CriteriaKey.FilterExpPeriodForIndustry]?: FilterExpPeriodOption;
    [CriteriaKey.JobSearchStatus]: JobSearchStatus[];
    [CriteriaKey.Language]: string[];
    [CriteriaKey.Schedule]: ScheduleCamelCase[];
    [CriteriaKey.WorkTicket]?: DataProviderItem[];
    [LabelOption.OnlyWithAge]?: boolean;
    [LabelOption.OnlyWithGender]?: boolean;
    [LabelOption.OnlyWithPhoto]?: boolean;
    [LabelOption.OnlyWithSalary]?: boolean;
    [LabelOption.OnlyWithVehicle]?: boolean;
    area?: string[];
    company?: CompanyShort;
    exclusion: ExclusionOption[];
    isStatisticsEnabled?: boolean;
    keySkills?: DataProviderItem[];
    metro?: MetroItem[];
    resume?: Resume | null;
    showSearchConditions?: boolean;
    university?: UniversityItem[];
    vacancy?: Vacancy | null;
    isExpIndustryHintVisible?: boolean;
    [CriteriaKey.VerifiedLanguages]: string[];
    districtsForArea?: MagritteDistrictItem[];
    metroForArea?: MagritteMetroItem[];
}

const ADVANCED_SEARCH_UPDATE_VERIFIED_LANGUAGE_FILTER = 'ADVANCED_SEARCH_UPDATE_VERIFIED_LANGUAGE_FILTER';
const ADVANCED_SEARCH_UPDATE_DISTRICTS_FOR_AREA = 'ADVANCED_SEARCH_UPDATE_DISTRICTS_FOR_AREA';
const ADVANCED_SEARCH_UPDATE_METRO_FOR_AREA = 'ADVANCED_SEARCH_UPDATE_METRO_FOR_AREA';

interface PayloadTypes {
    [ADVANCED_SEARCH_UPDATE_VERIFIED_LANGUAGE_FILTER]: string[];
    [ADVANCED_SEARCH_UPDATE_DISTRICTS_FOR_AREA]: MagritteDistrictItem[];
    [ADVANCED_SEARCH_UPDATE_METRO_FOR_AREA]: MagritteMetroItem[];
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const advancedSearchUpdateDistrictsForArea = actionCreator(ADVANCED_SEARCH_UPDATE_DISTRICTS_FOR_AREA);
export const advancedSearchUpdateMetroForArea = actionCreator(ADVANCED_SEARCH_UPDATE_METRO_FOR_AREA);
export const advancedSearchUpdateVerifiedLanguageFilter = actionCreator(
    ADVANCED_SEARCH_UPDATE_VERIFIED_LANGUAGE_FILTER
);

const INITIAL_STATE = {
    showSearchConditions: false,
    vacancy: null,
    resume: null,
    [CriteriaKey.Experience]: [],
    keySkills: [],
    university: [],
    [CriteriaKey.Citizenship]: [],
    [CriteriaKey.WorkTicket]: [],
    [CriteriaKey.Employment]: [],
    [CriteriaKey.Schedule]: [],
    [CriteriaKey.DriverLicenseTypes]: [],
    [CriteriaKey.FilterExpIndustry]: [],
    [CriteriaKey.JobSearchStatus]: [],
    [CriteriaKey.Language]: [],
    exclusion: [],
    [CriteriaKey.VerifiedLanguages]: [],
};

export default createReducer<AdvancedSearch, PayloadTypes>(INITIAL_STATE, {
    [ADVANCED_SEARCH_UPDATE_VERIFIED_LANGUAGE_FILTER]: (state, { payload }) => {
        const copy = { ...state };
        copy[CriteriaKey.VerifiedLanguages] = payload;
        return copy;
    },
    [ADVANCED_SEARCH_UPDATE_DISTRICTS_FOR_AREA]: (state, { payload }) => {
        return {
            ...state,
            districtsForArea: payload,
        };
    },
    [ADVANCED_SEARCH_UPDATE_METRO_FOR_AREA]: (state, { payload }) => {
        return {
            ...state,
            metroForArea: payload,
        };
    },
});
