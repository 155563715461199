import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import classnames from 'classnames';

import { Button, ButtonMode, Link as MagritteLink, TextTypography } from '@hh.ru/magritte-ui';
import {
    BarsOutlinedSize24,
    LocationFilledSize16,
    BarsOutlinedSize16,
    ChevronDownOutlinedSize16,
} from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import { DocumentedPropsWithChildren } from 'bloko/common/helpers/types';

import { NavItemAnalytics } from 'src/models/supernovaNaviMenu';

import useTrackClicks, { TrackClicksProps } from 'src/components/SupernovaMainMenu/useTrackClicks';

export enum SupernovaIconView {
    Mobile = 'mobile',
    MobileSmall = 'mobile-small',
    GeoSwitcher = 'geo-switcher',
    MoreItems = 'more-items',
}

const margitteIcons: Partial<Record<SupernovaIconView, React.FunctionComponent>> = {
    [SupernovaIconView.GeoSwitcher]: LocationFilledSize16,
    [SupernovaIconView.Mobile]: BarsOutlinedSize24,
    [SupernovaIconView.MobileSmall]: BarsOutlinedSize16,
    [SupernovaIconView.MoreItems]: ChevronDownOutlinedSize16,
};

interface SupernovaIconProps extends TrackClicksProps {
    active?: boolean;
    onClick?: React.MouseEventHandler;
    name: string;
    url?: string;
    analytics?: NavItemAnalytics;
    view: SupernovaIconView;
    reversed?: boolean;
    isMagritte?: boolean;
    magritteOnlyIcon?: boolean;
    magritteBadge?: React.ReactElement;
    magritteContrastStyle?: boolean;
    magritteButtonMode?: ButtonMode;
    magritteTypography?: TextTypography;
}

const ACTIVE_ATTRS = {
    disabled: true,
    tabIndex: -1,
};

const SupernovaIcon: ForwardRefRenderFunction<HTMLElement, DocumentedPropsWithChildren<SupernovaIconProps>> = (
    {
        active,
        children,
        name,
        onClick,
        url,
        view,
        trackClicks,
        analytics,
        reversed,
        isMagritte = false,
        magritteBadge,
        magritteOnlyIcon,
        magritteContrastStyle,
        magritteButtonMode = 'tertiary',
        magritteTypography = 'label-3-regular',
        ...props
    },
    forwardedRef
) => {
    const handleTrackClick = useTrackClicks(name, trackClicks, analytics);

    const handleClick = (event: React.MouseEvent) => {
        onClick && onClick(event);
        handleTrackClick();
    };

    if (isMagritte) {
        const IconComponent = margitteIcons[view];

        if (magritteOnlyIcon && IconComponent) {
            return (
                <Button
                    mode={magritteButtonMode}
                    aria-label={`navi-button-${name}`}
                    hideLabel
                    size="small"
                    style={magritteContrastStyle ? 'contrast' : 'neutral'}
                    badge={magritteBadge}
                    icon={<IconComponent />}
                    ref={forwardedRef as React.ForwardedRef<HTMLButtonElement>}
                    onClick={handleClick}
                />
            );
        }

        if (url) {
            return (
                <MagritteLink
                    style={magritteContrastStyle ? 'contrast' : 'neutral'}
                    typography={magritteTypography}
                    Element={Link}
                    to={url}
                    iconLeft={!reversed && IconComponent ? <IconComponent /> : undefined}
                    iconRight={reversed && IconComponent ? <IconComponent /> : undefined}
                    ref={forwardedRef as React.ForwardedRef<HTMLButtonElement>}
                    onClick={handleClick}
                    {...props}
                >
                    {children}
                </MagritteLink>
            );
        }
        return (
            <MagritteLink
                style={magritteContrastStyle ? 'contrast' : 'neutral'}
                typography={magritteTypography}
                iconLeft={!reversed && IconComponent ? <IconComponent /> : undefined}
                iconRight={reversed && IconComponent ? <IconComponent /> : undefined}
                ref={forwardedRef as React.ForwardedRef<HTMLButtonElement>}
                Element="button"
                onClick={handleClick}
                {...props}
            >
                {children}
            </MagritteLink>
        );
    }

    const icon = <span className={classnames('supernova-icon', { [`supernova-icon_${view}`]: view })} />;

    const content = reversed ? (
        <>
            {children}
            {icon}
        </>
    ) : (
        <>
            {icon}
            {children}
        </>
    );

    let wrapper;
    if (url) {
        wrapper = (
            <Link
                className="supernova-icon-link-switch"
                onClick={handleClick}
                ref={forwardedRef as React.Ref<HTMLAnchorElement>}
                to={url}
                {...(active ? ACTIVE_ATTRS : {})}
                {...props}
            >
                {content}
            </Link>
        );
    } else {
        wrapper = (
            <button
                className="supernova-icon-link-switch"
                onClick={handleClick}
                ref={forwardedRef as React.ForwardedRef<HTMLButtonElement>}
                type="button"
                {...(active ? ACTIVE_ATTRS : {})}
                {...props}
            >
                {content}
            </button>
        );
    }

    return <div className="supernova-icon-dynamic">{wrapper}</div>;
};
export default forwardRef(SupernovaIcon);
